import { pickBy, identity } from 'lodash'
import * as Sentry from '@sentry/browser'

//
// SEGMENT
//
export const track = (name, properties, sendToSlack = false) => {
  if (properties) {
    global.analytics.track(name, properties, {
      Slack: sendToSlack,
    })
  } else {
    global.analytics.track(
      name,
      {},
      {
        Slack: sendToSlack,
      }
    )
  }
}

export const identify = (user = {}, additional = {}) => {
  const address = () => {
    if (!user.street1 || !user.city || !user.state || !user.zip) {
      return null
    } else {
      const street = user.street2 ? `${user.street1} ${user.street2}` : user.street1
      return `${street} ${user.city}, ${user.state} ${user.zip}`
    }
  }

  const info = {
    firstName: user.firstName,
    lastName: user.lastName,
    name: user.name, // if we send just first and last name, segment creates this for us
    address: address(),
    displayName: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : null,
    email: user.email,
    company: user.company,
    phone: user.phone,
    createdAt: user.createdAt,
    teamId: user.team && user.team._id ? user.team._id : user.team,
    plan: additional.plan,
    lastLogIn: additional.lastLogIn,
    source: user.source,
  }

  // only returns properties with truthy value on object, no null/undefined/''/etc
  const userInfo = pickBy(info, identity)

  if (!user._id) {
    // anonymous identify
    global.analytics.identify(userInfo)
  } else {
    global.analytics.identify(user._id, userInfo)

    Sentry.configureScope(scope => {
      scope.setUser({ email: user.email })
    })
  }
}
