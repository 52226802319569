export const ACCESS_TOKEN = 'userAccessToken'
export const IMP_ID = 'impIdCookie'
export const PER_PAGE = 50
export const DOCS_URL = 'https://documentation.handwrite.io'
export const ZAP_URL = 'https://zapier.com/developer/public-invite/15091/5987ec3bfdf4c6439d364d254ed9d939'
export const SELF_SEND_MIN = 10
export const PHONE_NUMBER = '1-800-674-3260'
export const FREE_CREDITS = 3

export const INIT_SELF_SEND = {
  hasReturnAddress: false,
  hasCenterContent: false,
  centerContentType: 'none', // address, freeform
  centerContent: '',
  personalized: true,
  isSelfSend: false,
  inEnvelope: false,
  hasStamp: false,
  shipBoxTo: {},
  count: 0,
}

export const INITIAL_DRAFT = {
  message: '',
  recipients: [],
  from: {},
  card: {},
  handwriting: {},
}

export const confettiConfig = {
  angle: 90,
  spread: '120',
  startVelocity: 45,
  elementCount: '100',
  dragFriction: 0.1,
  duration: 3000,
  stagger: 0,
  width: '10px',
  height: '10px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a'],
}
